// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ausstattung-js": () => import("./../../../src/pages/ausstattung.js" /* webpackChunkName: "component---src-pages-ausstattung-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reservieren-js": () => import("./../../../src/pages/reservieren.js" /* webpackChunkName: "component---src-pages-reservieren-js" */)
}

